import { http } from '@/http/axios.js'

// 列表
export function getList(params) {
  return http({
    url: `/company/download/list`,
    method: 'get',
    params
  })
}

export function getAllList(params) {
  return http({
    url: `/company/download/getAllList`,
    method: 'get',
    params
  })
}

// 状态编辑
export function changeStatus(data) {
  return http({
    url: `/company/download/changeStatus`,
    method: 'post',
    data
  })
}

export function removeTeacher(params) {
  return http({
    url: `/company/download/delete`,
    method: 'post',
    params: params
  })
}
export function addDownload(data) {
  return http({
    url: `/company/download/add`,
    method: 'post',
    data
  })
}

export function editDownload(data) {
  return http({
    url: `/company/download/edit`,
    method: 'post',
    data
  })
}

export function detail(id) {
  return http({
    url: `/company/download/detail`,
    method: 'get',
    params: { id: id }
  })
}
